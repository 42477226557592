body {
  cursor: url('../public/images/cursor-2.png'), default;
  overflow-x: hidden !important;
}

a {
  cursor: url('../public/images/cursor-2.png'), default !important;
}

.center-text {
  text-align: center;
}

/* Temp loading block to hover the map during first render */
div.disable-map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
  z-index: 99999;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}
div.disable-map p {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  line-height: 24px;
  vertical-align: top;
}

div.disable-map p span {
  display: block;
}

#map {
  position: absolute;
  margin-top: 50px;
}

#map img {
  position: absolute;
}

img.field {
  /* clip-path: polygon(50% 10%, 100% 46%, 100% 63%, 50% 100%, 0 63%, 0 48%); */
}
img.heptagon {
  /*
  clip-path: polygon(
    50% 0,
    81% 16%,
    100% 45%,
    100% 63%,
    50% 100%,
    0 63%,
    0 48%,
    16% 25%
  );
  */
}

#root {
  max-width: 1200px;
  padding: 10px;
  margin: 0 auto;
}

.hidden {
  display: none !important;
}

.top-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.top-navigation p {
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 4px;
}

.top-navigation .nav-items {
  display: flex;
  flex-direction: row;
}

.top-navigation .nav-items .nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
}

.nav-item p a {
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 4px;
  text-decoration: none;
}

.top-navigation .nav-items .nav-item:not(:last-child):hover {
  /* cursor: pointer; */
  background: #ffffff2b;
  border-radius: 8px;
}

.balances-sheet-container {
  padding: 10px 20px;
  opacity: 0.8;
  background: linear-gradient(227.86deg, #232631 2.47%, #444a5c 97.53%);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 560px;
  margin: 0 auto;
  position: fixed;
  bottom: 20px;
  left: 50%;
  margin-left: -300px;
}

.balances-sheet-container .balance-container {
  width: 120px;
  display: flex;
}

.balances-sheet-container .balance-container img {
  margin-right: 2px;
  width: 40px;
}

.balances-sheet-container .balance-container .balance-text {
  color: white;
  font-family: Poppins;
  font-size: 16px;
  line-height: 40px;
}

.balance-container img.meta-resource {
  height: 30px;
  padding-top: 3px;
  padding-right: 7px;
  opacity: 100;
}

.jobs-modal-container {
  max-width: 1000px;
  margin: 115px auto 0;
  border-radius: 8px;
  background-color: #1a1c22;
  z-index: 1;
  outline: none;
}

.jobs-modal-container.quest-details-container {
  max-width: 800px;
  margin-top: 230px;
  padding-bottom: 32px;
  padding-left: 16px;
}

.jobs-modal-container.quest-details-container .quest-details-modal-body {
  display: flex;
  flex-direction: row;
}

.quest-details-container .quest-details-modal-body .quest-details-image-container {
  width: 45%;
}

.jobs-modal-container.quest-details-container .quest-details-modal-body .quest-details-image-container .quest-details-image {
  width: 100%;
  height: 100%;
}

.quest-details-container .quest-details-modal-body .quest-details-info-container {
  padding-left: 32px;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quest-details-rewards-container {
  display: flex;
  flex-direction: row;
}

.quest-details-rewards-container .quest-details-reward {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  width: 31%;
  height: 50px;
  margin-right: 2%;
  border: 1px solid #E08DEF;
  background: linear-gradient(124.61deg, rgba(130, 93, 251, 0.14) 14.75%, rgba(224, 141, 239, 0.14) 95.23%), #1C1D23;
  border-radius: 9px;
}

.quest-details-rewards-container .quest-details-reward .quest-details-reward-text {
  color: #FFBA01;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
}

.quest-details-rewards-container .quest-details-reward img {
  width: 40px;
  margin-right: 8px;
}

.quest-details-container .quest-details-modal-body .quest-details-info-container .quest-details-name {
  color: white;
  font-size: 24px;
  font-weight: 700;
  font-family: Poppins;
  margin-bottom: -5px;
}

.quest-details-container .quest-details-modal-body .quest-details-info-container .quest-details-desc {
  color: white;
  font-size: 14px;
  font-family: Poppins;
  margin-top: 24px;
  margin-bottom: 24px;
}

.jobs-modal-container.jobs-modal-workers, .quests-modal-body {
  height: 660px;
}

.jobs-modal-container .jobs-modal-header-container {
  display: flex;
  flex-direction: row;
  background: radial-gradient(
      50% 50% at 50% 50%,
      #363840 0%,
      rgba(57, 61, 78, 0) 100%
    ),
    #232631;
  border-radius: 8px 0 0 0;
}

.jobs-modal-container .jobs-modal-header {
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jobs-modal-container .jobs-modal-header span {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 1px 1px 0px #494949;
  padding-left: 24px;
}

/* .jobs-modal-container::before {
  z-index: 1;
  width: 70%;
  margin-left: 15%;
  margin-top: 10%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  background: radial-gradient(50% 50% at 50% 50%, #363840 0%, rgba(57, 61, 78, 0) 100%), #232631;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
} */

.jobs-modal-container .modal-close-icon {
  float: right;
  margin-top: -25px;
  margin-right: -25px;
  z-index: 5;
}

.jobs-modal-container .quests-modal-body,
.buildings-modal-body, .workers-modal-body {
  padding: 2rem 1.5rem;
  box-sizing: border-box;
}

.quests-modal-body {
  overflow-y: auto;
}

.workers-modal-body {
  overflow-y: auto;
  height: 85%;
}

.quest-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  border-radius: 8px;
}

.quest-row:hover {
  outline: 1px solid #8A61FA;
  border-radius: 8px;
  cursor: pointer;
}

.quest-row.coming-soon {
  filter: blur(3px);
}

.quest-row .quest-reward-container, .quest-row .quest-title-container {
  width: 38%;
  padding-left: 16px;
  box-sizing: border-box;
}

.quest-row .quest-actions-container {
  width: 24%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quest-row span {
  color: white;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
}

.jobs-modal-container
  .quests-modal-body
  .joquestsbs-table-row {
  margin-left: 12px;
  border: 1px solid #b4b6c1;
}

.jobs-modal-container .quests-modal-body .quests-table-row:nth-child(1n) {
  background-color: #232631;
}

.jobs-modal-container .quests-modal-body .quests-table-row:nth-child(2n) {
  background-color: rgba(35, 37, 49, 0.6);
}

.jobs-modal-container .jobs-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.jobs-modal-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #1a1c22;
  padding-bottom: 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.quests-table-header {
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

.quests-table-header div:not(:last-child) {
  width: 38%;
  padding-left: 0.5rem;
  box-sizing: border-box;
}

.quests-table-header .quests-table-header-text {
  color: #b4b6c1;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
}

.left-navigation-container {
  position: absolute;
  width: 85px;
  left: 38px;
  top: 40%;
}

.left-navigation-container .left-navigation-avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.left-navigation-container .left-navigation-avatar .avatar-image {
  position: absolute;
}

.left-navigation-container .left-navigation-avatar .background-container {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-end;
}

.left-navigation-container .left-navigation-avatar .background-container svg {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
}

.show-all-btn {
  background: linear-gradient(227.86deg, #232631 2.47%, #444a5c 97.53%);
  border-radius: 4px;
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.show-all-btn:hover {
  /* cursor: pointer; */
}

/* border-gradient */
.show-all-btn::before {
  height: 35px;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  background: linear-gradient(132.27deg, #4d536b 2.05%, #222631 100.47%)
      border-box,
    linear-gradient(0deg, #000000, #000000) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.show-all-btn-text {
  color: white;
  text-shadow: 1px 1px 0px #000000;
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
}

.loading {
  color: #fff;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 200px 0;
}

.loading img {
  width: 64px;
}

/* workers modal styles */
.workers-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  box-sizing: border-box;
  margin-top: -0.75%;
}

.worker-container {
  flex: 1 0 23%;
  flex-direction: column;
  height: 100%;
  margin: 0.75%;
  border-radius: 8px;
  background-color: #383A44;
  max-width: 23%;
  position: relative;
}

.worker-container.active {
  outline: 2px solid #8A61FA;
}

.worker-container .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 16px;
  height: 16px;
  background-color: #4C4F57;
  border: 1px solid #727375;
  border-radius: 2px;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
}

.worker-container .checkbox.active {
  background-color: #8A61FA;
}

.worker-container .worker-image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.worker-container .worker-image.busy {
  filter: grayscale(1);
  cursor: not-allowed;
}

.worker-container .worker-info-container {
  margin: 16px;
  font-family: "Poppins";
}

.worker-container .worker-info-container p {
  margin: 0;
}

.worker-container .worker-info-container .worker-info-status {
  display: flex;
  flex-direction: row;
  align-items: center;
} 

.worker-container .worker-info-container .worker-info-status .worker-status-busy {
  background-color: #FFBA01;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.worker-container .worker-info-container .worker-info-status .worker-status-free {
  background-color: #52AB73;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.worker-container .worker-info-container .worker-info-status p {
  margin-left: 4px;
  color: #B4B6C1;
  font-size: 12px;
}

.worker-container .worker-info-container .worker-info-name {
  color: white;
  font-size: 16px;
}

/* buildings modal styles */
.buildings-modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.building-info-container {
  display: flex;
  flex-direction: column;
  background: radial-gradient( 49.55% 33.08% at 50% 34.91%, #a36ef794 0%, #9a69f861 24.65%, rgba(26, 28, 33, 0.03) 88.19% ), #232631;
  width: 33%;
  padding: 1.2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid #925fff;
}

p.building-level {
  text-transform: uppercase;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  z-index: 99;
  text-align: center;
  background: url('../public/images/level-wrapper.png');
  background-size: cover;
  padding: 10px;
  min-height: 35px;
}

img.building-image {
  max-width: 230px;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
}

.building-info-container-wrapper {
  position: relative;
  width: 31.5%;
}

.building-info-container-wrapper .lock-icon-container {
  position: absolute;
  z-index: 2;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.building-info-container-locked {
  filter: grayscale(100%);
  display: flex;
  flex-direction: column;
  background: radial-gradient(
      49.55% 33.08% at 50% 34.91%,
      #a36ef7 0%,
      #9a69f8 24.65%,
      rgba(26, 28, 33, 0.03) 88.19%
    ),
    #232631;
  padding: 1.2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid #925fff;
}

.building-info-container::before {
  content: "";
  position: absolute;
  border-radius: 8px;
  border: 1px solid transparent;
  background: radial-gradient(
        100% 100% at 49.78% 0%,
        #925fff 0%,
        #c580f3 39.75%,
        rgba(255, 255, 255, 0) 93.4%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(180deg, #232631 0%, rgba(0, 0, 0, 0) 100%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.building-details-container {
  margin-top: 50px;
}

.building-details-container .building-status-text {
  color: #b4b6c1;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: -3rem;
  margin-bottom: 0.8rem;
}

.building-cost-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.building-cost-container .building-resourse-image {
  display: flex;
  background: #333644;
  justify-content: center;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  padding: 15px;
}

.building-cost-container .building-resourse-text-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  justify-content: center;
  align-items: center;
}

.building-cost-container
  .building-resourse-text-container
  .building-resourse-type {
  color: #b4b6c1;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
}

.building-cost-container
  .building-resourse-text-container
  .building-resourse-number {
  color: white;
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
}

.building-info-container .building-level-image,
.building-info-container-locked .building-level-image {
  display: flex;
  margin: 0 auto;
  margin-top: -0.5rem;
  margin-bottom: -2rem;
}

.building-info-container .building-rate-container {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #333644;
  height: 4rem;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.building-info-container .building-rate-container .rate-info-container {
  margin-left: 1rem;
}

.building-info-container .select-farming-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
}

.building-info-container .select-farming-container .number-of-workers {
  background: #333644;
  border-radius: 8px;
  border: none;
  height: 3.5rem;
  width: 5rem;
  color: white;
  font-size: 16px;
  padding-left: 0.8rem;
  box-sizing: border-box;
}

.building-info-container .select-farming-container > p {
  color: #b4b6c1;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 0.8rem;
}

.rate-info-container .resourse-rate-text {
  color: white;
  font-family: Poppins;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
}

.rate-info-container .hour-rate-container {
  display: flex;
  align-items: center;
}

.rate-info-container .hour-rate-text {
  color: #c580f3;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  margin-left: 0.3rem;
}

/* global */
.modal-close-icon svg:hover {
  /* cursor: pointer; */
}

.gradient-button.disabled {
  background: grey;
  cursor: not-allowed;
}

.gradient-button {
  height: 40px;
  min-width: 180px;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-button-pink {
  background: radial-gradient(
      116.41% 37.5% at 49.78% 0%,
      rgba(201, 174, 255, 0.91) 1.47%,
      rgba(255, 255, 255, 0) 46.52%
    ),
    linear-gradient(180deg, #8a61fa 0%, #d588f1 100%);
  background: #8A61FA;
}

.gradient-button-pink:hover {
  background: #A188E7;
}

.disabled.gradient-button-pink:hover {
  background: grey;
  cursor: not-allowed;
}

.gradient-button-green {
  background: linear-gradient(180deg, #afec4f 0%, #1c7b1c 100%);
}

.gradient-button-green:hover {
  background: linear-gradient(180deg, #cef88d 0%, #35a035 100%);
}

.gradient-button:hover {
  /* cursor: pointer; */
}

.gradient-button-text {
  color: white;
  font-family: Poppins;
  font-weight: 800;
  font-size: 16px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.38);
}

/* customSwitch styles */
.custom-switch-container {
  display: flex;
  margin: 0 auto;
  width: 50%;
  border-radius: 8px;
  margin-bottom: 16px;
  height: 2.5rem;
  flex-direction: "row";
  align-items: "center";
  justify-content: center;
  align-content: center;
  background-color: #383A44;
}

.custom-switch-container .option-container {
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 2px;
}

.custom-switch-container .option-container .option-text-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.custom-switch-container .option-container .option-text-container p {
  color: white;
  font-size: 16px;
  font-family: Poppins;
  margin: 0;
}

.custom-switch-container .option-container .divider {
  width: 2px;
  height: 80%;
  background-color: #545458;
}

.custom-switch-container .option-container.active {
  background-color: #8A61FA;
}

/* !important needed to overwrite lib classes */
.tooltip-container {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  height: 2rem !important;
  width: 2.25rem !important;
  padding: 8px !important;
  border-radius: 6px !important;
}

.display {
  display: block;
}

.hide {
  display: none;
}

.onboarding-container {
  background-color: #1a1c22;
}
